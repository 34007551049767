import { template as template_8d7bedcaf3fe4412a81f07682498a5e0 } from "@ember/template-compiler";
const WelcomeHeader = template_8d7bedcaf3fe4412a81f07682498a5e0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
