import { template as template_16532ab5f5d348f790458ef714272bfd } from "@ember/template-compiler";
import SearchMenuPanel from "../search-menu-panel";
const SearchMenuWrapper = template_16532ab5f5d348f790458ef714272bfd(`
  <div class="search-menu glimmer-search-menu" aria-live="polite" ...attributes>
    <SearchMenuPanel @closeSearchMenu={{@closeSearchMenu}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SearchMenuWrapper;
