import { template as template_afc4a387fe4f4d778b4bac11d26bcc2d } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import { i18n } from "discourse-i18n";
const UserSummarySection = template_afc4a387fe4f4d778b4bac11d26bcc2d(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
