import { template as template_83fea5d4a159425d88384b068dfa8f9d } from "@ember/template-compiler";
const FKLabel = template_83fea5d4a159425d88384b068dfa8f9d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
